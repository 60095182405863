
import { computed, createVNode, defineComponent, reactive, ref, toRefs, watch } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import {
  ExclamationCircleOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons-vue';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_CUSTOME_DATA_USER,
  GET_INFO,
  GET_MY_MENU_DATA_LIST,
  GET_NO_READ_NUMBER,
  GET_NO_SIGN_CONTRACT,
  GET_USER,
  LOGIN,
} from '@/store/modules/user/actions';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { check_init_password } from '@/api/sys-model';
import { get_adviser_sign_contract, retrieve_adviser_password } from '@/api/cerp-model';
import { change_password } from '@/api/sys';
import modalBox from '@/components/form-modal/modal-tools';
import { SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import FormModal from '@/components/form-modal/form-modal.vue';
import { get_process_tip_login } from '@/api/baseinfo-model';

export default defineComponent({
  name: 'Login',
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    if (navigator.platform.match('Android') ||
      navigator.platform.match('iPhone') ||
      navigator.platform.match('iPad') ||
      navigator.platform.match('iPod') ||
      navigator.platform.match('Linux') ||
      navigator.platform.match('BlackBerry')) {
      Modal.confirm({
        title: `当前不是PC设备，是否切换移动客户端    The current device is not a PC. Whether to switch to the mobile client`,
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          window.open('/static_file_h5/login', '_self');
        },
        onCancel() {
        },
      });
    }
    const go_to_iphone = () => {
      window.open('/static_file_h5/login', '_self');
    };
    const state = reactive({
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,

      time: 60,
      smsSendBtn: false,
    });
    const confirmLoading = ref<boolean>(false);
    const tip_url = ref('');
    const currentLang = computed(() => store.getters['app/lang']);
    const formModalConfig: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'vertical',
        col: [],
      },
      fields: [
        {
          type: 'input',
          name: 'email',
          label: '邮箱地址',
          label_i18n: 'page.cerp.login.form.label.email',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写邮箱地址',
          placeholder_i18n: 'page.cerp.login.form.placeholder.email',
        },
      ],
      rules: {
        email: [{ required: true, message: t('page.cerp.login.form.placeholder.email') }],
      },
      model: reactive({
        email: null,
      }),
    }

    const formModalConfigOptions = {}
    const editModalAdd = reactive({
      visible: false,
      title: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalAdd1 = reactive({
      visible: false,
      title: '123',
    });
    const handleUsernameOrEmail = (rule: any, value: any) => {
      return new Promise(resolve => {
        const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (regex.test(value)) {
          state.loginType = 0;
        } else {
          state.loginType = 1;
        }
        resolve(state.loginType);
      });
    };
    const tongyi: any = ref(false);
    const modelRef = reactive({
      rememberMe: true,
      username: '',
      password: '',
      mobile: '',
      currentLang: currentLang.value,
      tongyi: false,
    });
    const rulesRef = reactive({
      rememberMe: undefined,
      username: [
        { required: true, message: t('page.login.Please_enter'), type: 'string' },
        { validator: handleUsernameOrEmail, trigger: 'change' },
      ],
      password: [
        { required: true, message: t('page.login.Please_input_a_password'), type: 'string', trigger: ['blur', 'change'] },
      ],
      // mobile: [
      //   {
      //     required: true,
      //     // pattern: /^1[3456789]\d{9}$/,
      //     message: '请输入正确的手机号',
      //     trigger: ['blur', 'change'],
      //   },
      // ],
      // captcha: [{ required: true, message: '请输入验证码' }],
    });
    const { validateInfos, validate, resetFields } = useForm(modelRef, rulesRef);
    watch(() => modelRef.currentLang,
      () => {
        store.dispatch('app/SET_LANG', modelRef.currentLang);
      })
    const handleTabClick = (key: string) => {
      state.customActiveKey = key;
      resetFields();
    };

    const requestFailed = (err: AxiosError) => {
      console.log('requestFailed', err?.response?.data?.errorMessage);
      state.isLoginError = true;
      // notification.error({
      //   message: '错误',
      //   description: ((err.response || {}).data || {}).errorMessage || '请求出现错误，请稍后再试',
      //   duration: 4,
      // });
    };
    const handleChangePassword = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        loading: false,
        component: 'FormOkModal',
        title: t('page.login.Modify_default_password'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'horizontal',
          },
          fields: [
            {
              type: 'password',
              name: 'oldpassword',
              label: '旧密码',
              label_i18n: 'page.login.oldpassword',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
            {
              type: 'password',
              name: 'newpassword',
              label: '新密码',
              label_i18n: 'page.login.newpassword',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
            {
              type: 'password',
              name: 'newpassword2',
              label: '确认密码',
              label_i18n: 'page.login.newpassword2',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
          ],
          rules: reactive({
            oldpassword: [{ required: true, message: t('page.login.Please_oldpassword') }],
            newpassword: [{ required: true, message: t('page.login.Please_newpassword') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            oldpassword: null,
            newpassword: null,
            newpassword2: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          if (payload.newpassword !== payload.newpassword2) {
            notification.error({
              message: t('page.login.reminder'),
              description: t('page.login.The_two_passwords_are_inconsistent'),
            });
            return false;
          }
          return new Promise(resolve => {
            editModal.loading = true;
            change_password(payload)
              .then(() => {
                message.success(t('page.login.Password_modified_successfully'),);
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const loginSuccess = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const adviser =  store.getters[`user/adviser`];
      router.push({ path: '/adviser_workplace' });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: t('page.login.welcome'),
          description: t('page.login.welcome_back'),
        });
        state.isLoginError = false;
        check_init_password({
          org_id: current_org.id,
          user_id: adviser.adviser_user_id,
        }).then((res: any) => {
          if(res && res.need_change){
            handleChangePassword();
          }
        })
      }, 1000);
    };

    // const getCaptcha = (e: Event) => {
    //   e.preventDefault();
    //
    //   validate('mobile')
    //     .then(values => {
    //       state.smsSendBtn = true;
    //
    //       const interval = window.setInterval(() => {
    //         if (state.time-- <= 0) {
    //           state.time = 60;
    //           state.smsSendBtn = false;
    //           window.clearInterval(interval);
    //         }
    //       }, 1000);
    //
    //       const hide = message.loading('验证码发送中..', 0);
    //       getSmsCaptcha({ mobile: values.mobile })
    //         .then(res => {
    //           setTimeout(hide, 2500);
    //           notification.success({
    //             message: '提示',
    //             description: '验证码获取成功，您的验证码为：' + res.captcha,
    //             duration: 8,
    //           });
    //         })
    //         .catch(err => {
    //           setTimeout(hide, 1);
    //           clearInterval(interval);
    //           state.time = 60;
    //           state.smsSendBtn = false;
    //           requestFailed(err);
    //         });
    //     })
    //     .catch(err => {
    //       console.log('err', err);
    //     });
    // };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames =
        state.customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          console.log('values', values);
          store
            .dispatch(`user/${LOGIN}`, {
              ...values,
              username: values.username.replace(/(^\s*)|(\s*$)/g, ""),
              type: state.customActiveKey === 'tab1',
            })
            .then((res: any) => {
              store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                org_id: Number(res.org_list.data[0].id),
              });
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              store.dispatch(`user/${GET_USER}`,{ org_id: Number(current_org?.id) }).then(() =>{
                const adviser =  store.getters[`user/adviser`];
                get_adviser_sign_contract({
                  org_id: current_org.id,
                  adviser_id: adviser.adviser_id,
                }).then((res: any) => {
                  if(res && (!res.qiyuesuo_status || res.qiyuesuo_status < 3)){
                    store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: true });
                    router.push({ path: '/cc_adviser/Induction_process' });
                    // 延迟 1 秒显示欢迎信息
                    setTimeout(() => {
                      notification.success({
                        message: t('page.login.welcome'),
                        description: '请签署新合同',
                      });
                    }, 1000);
                  }else{
                    store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: false });
                    store
                      .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, { org_id: Number(current_org?.id) })
                      .then(() => {
                        store.dispatch(`user/${GET_INFO}`).then(() => {
                          store.dispatch(`user/${GET_NO_READ_NUMBER}`, {org_id: Number(current_org?.id)})
                          loginSuccess();
                        });
                      });
                  }
                })
              })

            })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };
    // this.loginBtn = false;
    // this.stepCaptchaVisible = false;
    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      retrieve_adviser_password({
        email: data.email,
      }).then(() => {
        editModalAdd.visible = false;
        message.success(t('page.login.Send_mail'),)
      }).finally(() => {
        confirmLoading.value = false;
      })
    }
    const gotologin = (checkedValue: any) => {
      if(tongyi.value){
        tongyi.value = false;
      }else{
        tongyi.value = true;
      }
    }
    get_process_tip_login({
      name: '《成诚顾问客户端》用户协议及隐私声明',
    }).then((res: any) => {
      if(res && res.files_json && res.files_json.file_url){
            tip_url.value = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + res.files_json.file_url;
      }
    })
    return {
      ...toRefs(state),
      t,
      tongyi,
      modelRef,
      tip_url,
      validateInfos,
      editModalAdd,
      editModalAdd1,
      gotologin,
      handleTabClick,
      go_to_iphone,
      handleSubmit,
      // currentLang,
      handlerOk,
      confirmLoading,
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    FormModal,
    MailOutlined,
  },
});
